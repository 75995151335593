import React, { ComponentProps, useRef } from "react";
import BoundingBox from "frontend/geometry/bounding-box";
import { ColorPicker } from "frontend/canvas-designer-new/elements-toolbar/widgets/colorPicker";
import type { IGanttSplitCellController } from "elements/gantt/controllers/split-cell-controller";
import styles from "./gantt-connector-toolbar.module.css";
import { TrashIcon } from "frontend/icons/trash-icon";
import constants, { TasksColors } from "elements/gantt/constants";
import GhostConnector from "frontend/canvas-designer-new/elements/connector/ghost-connector";

import Konva from "konva";
import { measureNodesScreenSpace } from "utils/node-utils";
import { GanttConnectorToolbar } from "elements/gantt/components/gantt-connector/gantt-connector-toolbar";

type GanttConnectorProps = {
  removeConnector: () => void;
  isSelected: boolean;
} & ComponentProps<typeof GhostConnector>;

export const GanttConnector = ({ removeConnector, isSelected, ...ghostConnectorProps }: GanttConnectorProps) => {
  const shapeRef = useRef<Konva.Shape>(null);

  const cellBbox: BoundingBox | null =
    shapeRef.current && BoundingBox.expandOnAll(measureNodesScreenSpace([shapeRef.current], { skipStroke: true }));

  return (
    <>
      <GhostConnector {...ghostConnectorProps} ref={shapeRef} />
      {isSelected && cellBbox && <GanttConnectorToolbar cellBbox={cellBbox} removeConnector={removeConnector} />}
    </>
  );
};
