import type { Point } from "../../../utils/math-utils";
import { vectorFromTo } from "../../../utils/point-utils";
import { Degrees } from "../../../utils/transform";
import { ConnectorEndpoint, IConnectorDrawingContext } from "./connector-utils";

type AnchorsModeList = [string | null, string | null];
const RADIUS = 20;
const ARROW_LEN = 50;
const LINE_MARGIN = ARROW_LEN + RADIUS;

export function getElBowForBackCompat({
  start,
  end,
  anchorMode,
  anchorsMode,
  anchorIndexes,
  activeAnchorIndex,
}: {
  start: ConnectorEndpoint;
  end: ConnectorEndpoint;
  anchorMode?: string | null;
  activeAnchorIndex?: number | null;
  anchorIndexes: number[];
  anchorsMode?: (string | null)[] | null;
}) {
  let flip = end.x > end.x ? 1 : -1;
  const width = end.x - start.x;
  const height = end.y - start.y;
  const dir = Math.sign(height);
  const radius = Math.min(RADIUS, Math.abs(height / 2), Math.abs(width / 2));
  const thirdLineHeight = Math.abs(end.y - dir * radius - (start.y + dir * radius));
  const lastLineWidth = Math.abs(start.x + width / 2 + radius * flip - end.x);
  const shouldFlip = lastLineWidth / thirdLineHeight < 0.5 && !anchorMode;

  // horizontal or vertical lines are special cases
  if (Math.abs(height) < 1e-3 || Math.abs(width) < 1e-3) {
    return axisAlignedElbowLine;
  }

  if (anchorsMode) {
    anchorsMode = [...anchorsMode];
    if (start.rotation != undefined) {
      anchorsMode[0] = getCardinalDirection(start.rotation as Degrees);
    }
    if (end.rotation != undefined) {
      anchorsMode[1] = getCardinalDirection(end.rotation as Degrees);
    }
    const otherAnchorIndex = 1 - activeAnchorIndex!;
    const activeAnchorMode = anchorsMode![activeAnchorIndex!];
    const otherAnchorMode = anchorsMode![otherAnchorIndex!];
    const specialModes = ["top", "buttom"];
    let index = activeAnchorIndex;
    let anchorMode = activeAnchorMode!;
    if (activeAnchorMode === otherAnchorMode) {
      index = 1; //give left anchor precedence
    } else if (!specialModes.includes(activeAnchorMode!) && specialModes.includes(otherAnchorMode!)) {
      index = otherAnchorIndex;
      anchorMode = otherAnchorMode!;
    }
    const isLeftAnchor = index === 0;
    switch (anchorMode) {
      case "top":
        if (isLeftAnchor) {
          return height < 0 ? createTopAnchoredLineLeftAnchor : createTopAnchoredLineBottomOrientationLeftAnchor;
        } else {
          return height < 0 ? createTopAnchoredLineBottomOrientation : createTopAnchoredLine;
        }
      case "buttom":
        if (isLeftAnchor) {
          return height < 0 ? createBottomAnchoredLineBottomOrientationLeftAnchor : createBottomAnchoredLineLeftAnchor;
        } else {
          return height < 0 ? createBottomAnchoredLineBottomOrientation : createBottomAnchoredLine;
        }
      default:
        return shouldFlip ? createFlippedLine : createLine;
    }
  } else {
    return shouldFlip ? createFlippedLine : createLine;
  }
}

// This function returns the direction of the line the elbow connector is attached to.
// The angle is the angle of the normal of the line (angle between normal and x-axis, clockwise)
function getCardinalDirection(angle: Degrees) {
  angle = ((angle + 360) % 360) as Degrees; // make sure it's in range 0..360
  if (angle <= 45) return "right";
  else if (angle <= 135) return "buttom";
  else if (angle <= 225) return "left";
  else if (angle <= 315) return "top";
  else return "right";
}

// lines where abs(dx) > abs(dy)
function createLine(
  context: IConnectorDrawingContext,
  shape: any,
  points: { x: number; y: number }[],
  isConnected: boolean,
  anchorsMode: AnchorsModeList
) {
  const width = points[1].x - points[0].x;
  const height = points[1].y - points[0].y;
  const dir = Math.sign(height);
  const radius = Math.min(RADIUS, Math.abs(height / 2));
  let flipX = Math.sign(width);
  let yRadius = 0;

  context.beginPath();
  context.moveTo(points[0].x, points[0].y);
  if (isConnected) {
    const topLeft: AnchorsModeList = ["top", "left"];
    const topRight: AnchorsModeList = ["top", "right"];
    const bottomLeft: AnchorsModeList = ["buttom", "left"];
    const bottomRight: AnchorsModeList = ["buttom", "right"];

    if (areAnchorsInPosition(anchorsMode, topLeft) || areAnchorsInPosition(anchorsMode, topRight)) {
      yRadius = 20;
      context.quadraticCurveTo(points[0].x, points[0].y - yRadius, points[0].x, points[0].y - yRadius);
    } else if (areAnchorsInPosition(anchorsMode, bottomLeft) || areAnchorsInPosition(anchorsMode, bottomRight)) {
      context.quadraticCurveTo(points[0].x, points[0].y - radius, points[0].x, points[0].y);
    }
  }
  context.lineTo(points[0].x + width / 2 - RADIUS * flipX, points[0].y - yRadius);
  context.quadraticCurveTo(
    points[0].x + width / 2,
    points[0].y - yRadius,
    points[0].x + width / 2,
    points[0].y - yRadius + dir * radius
  );
  context.lineTo(points[0].x + width / 2, points[1].y - dir * radius);
  context.quadraticCurveTo(points[0].x + width / 2, points[1].y, points[0].x + width / 2 + radius * flipX, points[1].y);
  context.lineTo(points[1].x, points[1].y);
}

// lines where abs(dy) > abs(dx_)
function createFlippedLine(context: IConnectorDrawingContext, shape: any, points: { x: number; y: number }[]) {
  const width = points[1].x - points[0].x;
  const height = points[1].y - points[0].y;
  const radius = Math.min(RADIUS, Math.abs(width / 2));
  const signY = height > 0 ? 1 : -1;
  const signX = width > 0 ? 1 : -1;
  const midpointY = (points[0].y + points[1].y) / 2;

  context.beginPath();
  context.moveTo(points[0].x, points[0].y);
  context.lineTo(points[0].x, midpointY - radius * signY);
  context.quadraticCurveTo(points[0].x, midpointY, points[0].x + radius * signX, midpointY);
  context.lineTo(points[1].x - radius * signX, midpointY);
  context.quadraticCurveTo(points[1].x, midpointY, points[1].x, midpointY + radius * signY);
  context.lineTo(points[1].x, points[1].y);
}

function areAnchorsInPosition(anchorsMode: AnchorsModeList, posArray: AnchorsModeList): boolean {
  const test1 = anchorsMode[0] == posArray[0] && anchorsMode[1] == posArray[1];
  const test2 = anchorsMode[0] == posArray[1] && anchorsMode[1] == posArray[0];
  return test1 || test2;
}

function createTopAnchoredLineLeftAnchor(
  context: IConnectorDrawingContext,
  shape: any,
  points: { x: number; y: number }[],
  isConnected: boolean,
  anchorsMode: AnchorsModeList
) {
  const width = points[1].x - points[0].x;
  const height = points[1].y - points[0].y;
  const radius = Math.min(RADIUS, Math.abs(height / 2), Math.abs(width / 2));
  if (isConnected) {
    if (anchorsMode[1] == "right" && points[0].x > points[1].x) {
      drawElbowLine(context, [points[0], { x: points[0].x, y: points[1].y }, points[1]]);
      return;
    }
    if (anchorsMode[1] == "left" && points[0].x < points[1].x) {
      drawElbowLine(context, [points[0], { x: points[0].x, y: points[1].y }, points[1]]);
      return;
    }
    if (anchorsMode[1] == "right" || anchorsMode[1] == "left") {
      let xSign = anchorsMode[1] == "right" ? 1 : -1;
      drawElbowLine(context, [
        points[0],
        { x: points[0].x, y: points[0].y - LINE_MARGIN },
        { x: points[1].x + xSign * LINE_MARGIN, y: points[0].y - LINE_MARGIN },
        { x: points[1].x + xSign * LINE_MARGIN, y: points[1].y },
        points[1],
      ]);
      return;
    }
    drawElbowLine(context, [
      points[0],
      { x: points[0].x, y: points[0].y - LINE_MARGIN },
      { x: points[1].x, y: points[0].y - LINE_MARGIN },
      points[1],
    ]);
  } else {
    drawElbowLine(context, [points[0], { x: points[0].x, y: points[1].y }, points[1]]);
  }
}

function createTopAnchoredLineBottomOrientationLeftAnchor(
  context: IConnectorDrawingContext,
  shape: any,
  points: { x: number; y: number }[],
  isConnected: boolean,
  anchorsMode: AnchorsModeList
) {
  if (isConnected) {
    const isTopBottom = areAnchorsInPosition(anchorsMode, ["top", "buttom"]);
    const isRightTop = areAnchorsInPosition(anchorsMode, ["right", "top"]);
    if (isTopBottom) {
      const xmid = (points[0].x + points[1].x) / 2;
      drawElbowLine(context, [
        points[0],
        {
          x: points[0].x,
          y: points[0].y - LINE_MARGIN,
        },
        {
          x: xmid,
          y: points[0].y - LINE_MARGIN,
        },
        {
          x: xmid,
          y: points[1].y + LINE_MARGIN,
        },
        {
          x: points[1].x,
          y: points[1].y + LINE_MARGIN,
        },
        points[1],
      ]);
    } else {
      let xSign = isRightTop ? 1 : -1;
      let linePoints = [
        points[0],
        {
          x: points[0].x,
          y: points[0].y - LINE_MARGIN,
        },
        {
          x: points[1].x + xSign * LINE_MARGIN,
          y: points[0].y - LINE_MARGIN,
        },
        {
          x: points[1].x + xSign * LINE_MARGIN,
          y: points[1].y,
        },
        points[1],
      ];
      drawElbowLine(context, linePoints);
    }
  } else {
    drawElbowLine(context, [
      points[0],
      {
        x: points[0].x,
        y: points[0].y - LINE_MARGIN,
      },
      {
        x: points[1].x,
        y: points[0].y - LINE_MARGIN,
      },
      points[1],
    ]);
  }
}

function createTopAnchoredLineBottomOrientation(
  context: IConnectorDrawingContext,
  shape: any,
  points: { x: number; y: number }[],
  isConnected: boolean,
  anchorsMode: AnchorsModeList
) {
  if (isConnected) {
    const isLeft = areAnchorsInPosition(anchorsMode, ["left", "top"]);
    let xSign = isLeft ? 1 : -1;
    if (anchorsMode[0] == "buttom" && anchorsMode[1] == "top") {
      const xmid = (points[0].x + points[1].x) / 2;
      drawElbowLine(context, [
        points[0],
        {
          x: points[0].x,
          y: points[0].y + LINE_MARGIN,
        },
        {
          x: xmid,
          y: points[0].y + LINE_MARGIN,
        },
        {
          x: xmid,
          y: points[1].y - LINE_MARGIN,
        },
        {
          x: points[1].x,
          y: points[1].y - LINE_MARGIN,
        },
        points[1],
      ]);
    } else if (anchorsMode[0] == "top" && anchorsMode[1] == "top") {
      drawElbowLine(context, [
        points[0],
        {
          x: points[0].x,
          y: points[1].y - LINE_MARGIN,
        },
        {
          x: points[1].x,
          y: points[1].y - LINE_MARGIN,
        },
        points[1],
      ]);
    } else {
      drawElbowLine(context, [
        points[0],
        {
          x: points[0].x - xSign * LINE_MARGIN,
          y: points[0].y,
        },
        {
          x: points[0].x - xSign * LINE_MARGIN,
          y: points[1].y - LINE_MARGIN,
        },
        {
          x: points[1].x,
          y: points[1].y - LINE_MARGIN,
        },
        points[1],
      ]);
    }
  } else {
    let linePoints = [
      points[0],
      {
        x: points[0].x,
        y: points[0].y - LINE_MARGIN,
      },
      {
        x: points[0].x,
        y: points[1].y - LINE_MARGIN,
      },
      {
        x: points[1].x,
        y: points[1].y - LINE_MARGIN,
      },
      points[1],
    ];
    drawElbowLine(context, linePoints);
  }
}

function createTopAnchoredLine(
  context: IConnectorDrawingContext,
  shape: any,
  points: { x: number; y: number }[],
  isConnected: boolean,
  anchorsMode: AnchorsModeList
) {
  if (isConnected) {
    const isTopLeft = areAnchorsInPosition(anchorsMode, ["left", "top"]);
    const isTopTop = areAnchorsInPosition(anchorsMode, ["top", "top"]);
    const isBottomTop = areAnchorsInPosition(anchorsMode, ["buttom", "top"]);
    if (isTopTop) {
      drawElbowLine(context, [
        points[0],
        {
          x: points[0].x,
          y: points[0].y - LINE_MARGIN,
        },
        {
          x: points[1].x,
          y: points[0].y - LINE_MARGIN,
        },
        points[1],
      ]);
    } else if (isBottomTop) {
      const midY = points[0].y / 2 + points[1].y / 2;
      drawElbowLine(context, [
        points[0],
        {
          x: points[0].x,
          y: midY,
        },
        {
          x: points[1].x,
          y: midY,
        },
        points[1],
      ]);
    } else {
      let xSign = isTopLeft ? -1 : 1;
      let linePoints = [
        points[0],
        {
          x: points[0].x + xSign * LINE_MARGIN,
          y: points[0].y,
        },
        {
          x: points[0].x + xSign * LINE_MARGIN,
          y: points[1].y - LINE_MARGIN,
        },
        {
          x: points[1].x,
          y: points[1].y - LINE_MARGIN,
        },
        points[1],
      ];
      drawElbowLine(context, linePoints);
    }
  } else {
    drawElbowLine(context, [
      points[0],
      { x: points[0].x, y: points[1].y - LINE_MARGIN },
      { x: points[1].x, y: points[1].y - LINE_MARGIN },
      points[1],
    ]);
  }
}

function createBottomAnchoredLineBottomOrientationLeftAnchor(
  context: IConnectorDrawingContext,
  shape: any,
  points: { x: number; y: number }[],
  isConnected: boolean,
  anchorsMode: AnchorsModeList
) {
  if (isConnected) {
    const isBottomLeft = areAnchorsInPosition(anchorsMode, ["buttom", "left"]);
    let xSign = isBottomLeft ? -1 : -1;

    if (anchorsMode[0] == "buttom" && anchorsMode[1] == "top") {
      const xmid = (points[0].x + points[1].x) / 2;
      drawElbowLine(context, [
        points[0],
        {
          x: points[0].x,
          y: points[0].y + LINE_MARGIN,
        },
        {
          x: xmid,
          y: points[0].y + LINE_MARGIN,
        },
        {
          x: xmid,
          y: points[1].y - LINE_MARGIN,
        },
        {
          x: points[1].x,
          y: points[1].y - LINE_MARGIN,
        },
        points[1],
      ]);
      return;
    }

    if (isBottomLeft) xSign = -xSign;
    let linePoints = [
      points[0],
      { x: points[0].x, y: points[0].y + LINE_MARGIN },
      {
        x: points[1].x - xSign * LINE_MARGIN,
        y: points[0].y + LINE_MARGIN,
      },
      {
        x: points[1].x - xSign * LINE_MARGIN,
        y: points[1].y,
      },
      points[1],
    ];
    drawElbowLine(context, linePoints);
  } else {
    let linePoints = [
      points[0],
      { x: points[0].x, y: points[0].y + LINE_MARGIN },
      { x: points[1].x, y: points[0].y + LINE_MARGIN },
      points[1],
    ];
    drawElbowLine(context, linePoints);
  }
}

function createBottomAnchoredLineLeftAnchor(
  context: IConnectorDrawingContext,
  shape: any,
  points: { x: number; y: number }[],
  isConnected: boolean,
  anchorsMode: AnchorsModeList
) {
  if (isConnected) {
    if (anchorsMode[0] == "buttom" && anchorsMode[1] == "top") {
      const midY = points[0].y / 2 + points[1].y / 2;
      drawElbowLine(context, [
        points[0],
        {
          x: points[0].x,
          y: midY,
        },
        {
          x: points[1].x,
          y: midY,
        },
        points[1],
      ]);
      return;
    }
    drawElbowLine(context, [points[0], { x: points[0].x, y: points[1].y }, points[1]]);
  } else {
    drawElbowLine(context, [points[0], { x: points[0].x, y: points[1].y }, points[1]]);
  }
}

function createBottomAnchoredLineBottomOrientation(
  context: IConnectorDrawingContext,
  shape: any,
  points: { x: number; y: number }[],
  isConnected: boolean,
  anchorsMode: AnchorsModeList
) {
  if (isConnected) {
    if (anchorsMode[0] == "buttom") {
      drawElbowLine(context, [
        points[0],
        {
          x: points[0].x,
          y: points[0].y + LINE_MARGIN,
        },
        {
          x: points[1].x,
          y: points[0].y + LINE_MARGIN,
        },
        points[1],
      ]);
    } else if (anchorsMode[0] == "left" || anchorsMode[0] == "right") {
      const isRightBottom = anchorsMode[0] == "right";
      let xSign = isRightBottom ? +1 : -1;
      drawElbowLine(context, [
        points[0],
        {
          x: points[0].x + xSign * (ARROW_LEN + RADIUS),
          y: points[0].y,
        },
        {
          x: points[0].x + xSign * (ARROW_LEN + RADIUS),
          y: points[1].y + (ARROW_LEN + RADIUS),
        },
        {
          x: points[1].x,
          y: points[1].y + (ARROW_LEN + RADIUS),
        },
        points[1],
      ]);
    } else {
      const midY = points[0].y / 2 + points[1].y / 2;
      drawElbowLine(context, [
        points[0],
        {
          x: points[0].x,
          y: midY, //points[0].y - LINE_MARGIN,
        },
        {
          x: points[1].x,
          y: midY, //points[0].y - LINE_MARGIN,
        },
        points[1],
      ]);
    }
    return;
  } else {
    drawElbowLine(context, [points[0], { x: points[1].x, y: points[0].y }, points[1]]);
  }
}

function createBottomAnchoredLine(
  context: IConnectorDrawingContext,
  shape: any,
  points: { x: number; y: number }[],
  isConnected: boolean,
  anchorsMode: AnchorsModeList
) {
  if (isConnected) {
    const isRightBottom = areAnchorsInPosition(anchorsMode, ["right", "buttom"]);
    const isLeftBottom = areAnchorsInPosition(anchorsMode, ["left", "buttom"]);
    const isBottomBottom = !isRightBottom && !isLeftBottom;
    if (isBottomBottom) {
      drawElbowLine(context, [
        points[0],
        {
          x: points[0].x,
          y: points[1].y + LINE_MARGIN,
        },
        {
          x: points[1].x,
          y: points[1].y + LINE_MARGIN,
        },
        points[1],
      ]);
    } else {
      let xSign = isRightBottom ? +1 : isLeftBottom ? -1 : 0;
      drawElbowLine(context, [
        points[0],
        {
          x: points[0].x + xSign * LINE_MARGIN,
          y: points[0].y,
        },
        {
          x: points[0].x + xSign * LINE_MARGIN,
          y: points[1].y + (ARROW_LEN + RADIUS),
        },
        {
          x: points[1].x,
          y: points[1].y + (ARROW_LEN + RADIUS),
        },
        points[1],
      ]);
    }
  } else {
    drawElbowLine(context, [
      points[0],
      {
        x: points[0].x,
        y: points[1].y + (ARROW_LEN + RADIUS),
      },
      {
        x: points[1].x,
        y: points[1].y + (ARROW_LEN + RADIUS),
      },
      points[1],
    ]);
  }
}

function drawElbowLine(context: IConnectorDrawingContext, line: Point[]) {
  function sign(p: Point) {
    return { x: Math.sign(p.x), y: Math.sign(p.y) };
  }
  function bigSideLen(p: Point) {
    return Math.max(Math.abs(p.x), Math.abs(p.y));
  }

  context.beginPath();
  context.moveTo(line[0].x, line[0].y);
  let prev = line[0];
  for (let i = 1; i < line.length - 1; i++) {
    let fromPrev = vectorFromTo(prev, line[i]);
    let toNext = vectorFromTo(line[i], line[i + 1]);
    let lengthPrev = bigSideLen(fromPrev),
      lengthNext = bigSideLen(toNext);
    fromPrev = sign(fromPrev);
    toNext = sign(toNext);
    let radius = Math.min(RADIUS, lengthPrev / 2, lengthNext / 2);
    const nextPoint = {
      x: line[i].x + radius * toNext.x,
      y: line[i].y + radius * toNext.y,
    };
    context.lineTo(line[i].x - radius * fromPrev.x, line[i].y - radius * fromPrev.y);
    //TODO: use canvas's arcTo command which is intended just for this
    context.quadraticCurveTo(line[i].x, line[i].y, nextPoint.x, nextPoint.y);
    prev = nextPoint;
  }
  context.lineTo(line[line.length - 1].x, line[line.length - 1].y);
}

function axisAlignedElbowLine(
  context: IConnectorDrawingContext,
  shape: any,
  points: { x: number; y: number }[],
  isConnected: boolean,
  anchorsMode: AnchorsModeList
) {
  const last = points.length - 1;
  context.beginPath();
  context.moveTo(points[0].x, points[0].y);
  context.lineTo(points[last].x, points[last].y);
}
