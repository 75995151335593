import React, { useMemo, useRef, useState, forwardRef, Ref } from "react";
import Konva from "konva";
import { Group, Line, Shape } from "react-konva";
import { Point, Connector } from "shared/datamodel/schemas";
import { parseStrokeWidth } from "shared/util/utils";
import { calcDashProperties } from "frontend/utils/node-utils";
import { IRect } from "frontend/utils/math-utils";
import { useAtomValue } from "jotai";
import { isThumbnailExportModeAtom } from "state-atoms";
import { ConnectorTextLabel } from "../../text-element";
import { PointAndDirection, toRadians } from "frontend/utils/transform";
import type { Degrees } from "frontend/utils/transform";
import { initArray, minIndexBy } from "frontend/utils/fn-utils";
import { ConnectorEndpoint, fixAnchorsMode } from "./connector-utils";
import * as utils from "./connector-utils";
import * as elbowUtils from "./elbow-connector-utils";
import * as PointUtils from "frontend/utils/point-utils";

type Point2 = readonly [number, number];

export function ConnectorLineAndText({
  p1,
  p2,
  element,
  data,
  isEditing,
  onChangeElement,
}: {
  p1: PointAndDirection;
  p2: PointAndDirection;
  element: Connector;
  data: utils.SimpleConnectorData;
  isEditing: boolean;
  onChangeElement?: utils.OnChangeElementFn;
}) {
  const isThumbnailExport = useAtomValue(isThumbnailExportModeAtom);
  const [textSize, setTextSize] = useState<{ width: number; height: number }>({ width: 0, height: 48 });
  const updateTextSize = (w: number, h: number) => {
    if (w != textSize.width || h != textSize.height) setTextSize({ width: w, height: h });
  };
  const { scaleX = 1, scaleY = 1, textLocation = 0.5 } = element;
  const undoRef = useRef<any>(null);

  function computeTextPosition() {
    if (element.lineType == "elbow") {
      const segments = new utils.RecordCanvasCmds();
      let renderer = elbowUtils.getElBowForBackCompat({
        ...element,
        start: p1,
        end: p2,
      });
      const isConnected = !!element.anchorsMode && element.anchorsMode.every((x) => fixAnchorsMode(x) != "n/a");
      renderer(segments, null, [p1, p2], isConnected, element.anchorsMode! as any);
      return utils.evaluatePointInCurve(segments.segments)(textLocation);
    }

    if (data.segments.length == 0) {
      console.warn("don't have data.segments for connector text label");
      return [0, 0];
    }
    let metrics = new utils.PathMetrics(data);
    const anchorPoint = metrics.getPointAlongPath(textLocation);
    return anchorPoint;
  }

  function getEvaluator(): (t: number) => Point2 {
    if (element.lineType == "elbow") {
      const segments = new utils.RecordCanvasCmds();
      let renderer = elbowUtils.getElBowForBackCompat({
        ...element,
        start: p1,
        end: p2,
      });
      const isConnected = !!element.anchorsMode && element.anchorsMode.every((x) => fixAnchorsMode(x) != "n/a");
      renderer(segments, null, [p1, p2], isConnected, element.anchorsMode! as any);
      return utils.evaluatePointInCurve(segments.segments);
    }

    //TODO: path metrics is a wasteful way to calculate points along the path.
    let metrics = new utils.PathMetrics(data);
    return metrics.getPointAlongPath.bind(metrics);
  }

  // TODO: when moving the text label we update element.textLocation and then recompute
  // textLabelPosition. This is very wasteful since we recompute points along the path!
  const textLabelPosition = useMemo(computeTextPosition, [
    element.lineType,
    element.textLocation,
    element.text,
    p1,
    p2,
    data,
  ]);

  const hasText = element.text?.length || isEditing;

  let clipRect: undefined | IRect = undefined;
  if (hasText) {
    clipRect = {
      x: textLabelPosition[0] - textSize.width / scaleX / 2,
      y: textLabelPosition[1] - textSize.height / scaleY / 2,
      width: textSize.width / scaleX,
      height: textSize.height / scaleY,
    };
  }

  const onTextDrag = (e: Konva.KonvaEventObject<DragEvent>) => {
    //TODO: when user starts pressing space she's moving the stage. abort drag of element
    //    if (isSpacePressed) {
    //      e.target.stopDrag();
    //      return;
    //    }

    const type = (e as any).type;
    if (type == "dragstart") {
      const getpoint = getEvaluator();
      const curvePoints = initArray(1001, (n) => getpoint(n / 1000));
      undoRef.current = { textLocation, curvePoints };
    } else if (type == "dragend") {
      // TODO: I can calculate the closest point on the path to the mouse position here.
      // in dragmove I go for faster solution, but here I can go for best
      onChangeElement &&
        onChangeElement(
          { textLocation: element.textLocation },
          {
            shouldAdd: true,
            previousProps: {
              textLocation: undoRef.current.textLocation,
            },
          }
        );
      undoRef.current = null;
    } else if (type == "dragmove") {
      const mousePos = e.currentTarget.position();
      const distanceToMouse = (p: Point2) => PointUtils.lenSqr({ x: p[0], y: p[1] }, mousePos);
      const curvePoints = undoRef.current.curvePoints;
      let [, indexClosestCurvePoint] = minIndexBy(distanceToMouse, curvePoints);
      const t = indexClosestCurvePoint / (curvePoints.length - 1);
      onChangeElement && onChangeElement({ textLocation: t }, { shouldAdd: false });
    }
  };

  return (
    <>
      <ConnectorLine p1={p1} p2={p2} element={element} data={data} clipRect={clipRect} />
      {!isThumbnailExport && textLabelPosition && (
        <ConnectorTextLabel
          element={element}
          updateText={(initial: string, text: string) => {
            onChangeElement && onChangeElement({ text }, { shouldAdd: true, previousProps: { text: initial } });
          }}
          position={{ x: textLabelPosition[0], y: textLabelPosition[1] }}
          textSize={textSize}
          updateTextSize={updateTextSize}
          isEditing={isEditing}
          onTextDrag={onTextDrag}
        />
      )}
    </>
  );
}

export const ConnectorLine = forwardRef(function ConnectorLine(
  {
    p1,
    p2,
    element,
    data,
    clipRect,
    id,
  }: {
    p1: ConnectorEndpoint;
    p2: ConnectorEndpoint;
    data: utils.SimpleConnectorData;
    clipRect?: IRect;
    id?: string;
    element: {
      scaleX?: number;
      scaleY?: number;
      rotate?: number;
      stroke: string;
      strokeWidth: number | string;
      pointerStyles: ("arrow" | "none")[];
      anchorsMode?: null | (null | string)[];
      anchorIndexes: number[];
      activeAnchorIndex?: number | null;
      dash?: number;
      lineType: "line" | "curve" | "elbow";
      groupId?: string;
      hitStrokeWidth?: number;
    };
  },
  ref: Ref<Konva.Shape>
) {
  const scaleX = element.scaleX ?? 1;
  const scaleY = element.scaleY ?? 1;
  const strokeWidth = parseStrokeWidth(element.strokeWidth);
  const strokeColor = element.stroke;
  const pointerStyles = element.pointerStyles;
  const selfRect = useMemo(() => data.getSelfRect(), [data]);
  // TODO: visual style (width,color,dash,arrows) should be extracted by caller and passed as props
  // so ghost element can supply them
  const distBetweenArrowHeads = PointUtils.distance(p1, p2);
  const maxArrowHeadSize = distBetweenArrowHeads / 2.5;

  function scale(p: Point) {
    p.x *= scaleX;
    p.y *= scaleY;
    return p;
  }
  return (
    <>
      <Shape
        id={id}
        ref={ref}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeEnabled={true}
        hitStrokeWidth={element.hitStrokeWidth ?? 0}
        activeAnchorIndex={element.activeAnchorIndex}
        lineJoin="round"
        points={[p1, p2]} // TODO: needed for elbow code, which should be depracted
        connectorData={data}
        {...calcDashProperties(strokeWidth, element.dash)}
        sceneFunc={(context, shape) => {
          // Clip the area where the text label is
          if (clipRect) {
            if ((context as any).isPdfContext) {
              (context as any).cliphole(clipRect, element);
            } else {
              clipHole(context, clipRect, scaleX, scaleY, element.rotate as Degrees);
            }
          }

          // Elbow lines are drawn here, because they stil use the old algorithm
          if (element.lineType == "elbow") {
            let renderer = elbowUtils.getElBowForBackCompat({
              start: p1,
              end: p2,
              ...element,
            });
            const isConnected =
              !!element.anchorsMode && element.anchorsMode.every((x: any) => fixAnchorsMode(x) != "n/a");

            // first render to an internal object to record the commands so we can calculate stuff.
            let pathSegments = new utils.RecordCanvasCmds();
            renderer(pathSegments, shape, [p1, p2], isConnected, element.anchorsMode! as any);
            // now render and stroke without the scale
            renderer(context, shape, [p1, p2], isConnected, element.anchorsMode! as any);
            context.scale(1 / scaleX, 1 / scaleY);
            context.strokeShape(shape);

            // === Drawing arrows ===
            if (pointerStyles && (pointerStyles[0] != "none" || pointerStyles[1] != "none")) {
              context.beginPath();
              if (pointerStyles[0] === "arrow") {
                const position = scale(utils.lastPoint(pathSegments.segments));
                const dir = utils.endTangent(pathSegments.segments);
                const [p1, p2] = utils.computeArrowPoints(position, dir, strokeWidth, maxArrowHeadSize);
                context.moveTo(p1.x, p1.y);
                context.lineTo(position.x, position.y);
                context.lineTo(p2.x, p2.y);
              }
              if (pointerStyles[1] === "arrow") {
                const position = scale(utils.firstPoint(pathSegments.segments));
                const dir = utils.startTangent(pathSegments.segments);
                const [p1, p2] = utils.computeArrowPoints(position, dir, strokeWidth, maxArrowHeadSize);
                context.moveTo(p1.x, p1.y);
                context.lineTo(position.x, position.y);
                context.lineTo(p2.x, p2.y);
              }
              let arrowShape: Konva.Shape = new Konva.Shape({
                dash: [],
                dashEnabled: true,
                lineCap: "round",
                lineJoin: "round",
                stroke: element.stroke,
                strokeWidth: strokeWidth,
                strokeEnabled: true,
              });
              context.strokeShape(arrowShape);
            }

            let box = utils.calcBbox(pathSegments.segments);

            let x = box.x;
            let y = box.y;
            let width = box.width;
            let height = box.height;
            shape.getSelfRect = () => ({ x, y, width, height });
            box = box.addPadding(strokeWidth / 2);
            shape.attrs.bbox = box.asRect();
          } else {
            // Line and bezier curves are drawn here using the new class
            context.beginPath();
            data.drawOnCanvas(context);
            // remove scale so stroke isn't scaled
            context.scale(1 / scaleX, 1 / scaleY);
            context.strokeShape(shape);
            // make sure selfRect is correct for this shape
            shape.getSelfRect = () => selfRect;
          }
        }}
      />
      {pointerStyles && element.lineType != "elbow" && (
        // We undo the scale because for the purposes of computing arrows they interfere
        // The arrow size should be the same regardless of connector scale,
        // and arrow
        <Group scaleX={1 / scaleX} scaleY={1 / scaleY}>
          <ArrowHead
            position={scale(data.finalPoint())}
            comingFrom={scale(data.preFinalPoint())}
            arrowHead={pointerStyles[0]}
            strokeWidth={strokeWidth}
            strokeColor={strokeColor}
            allowedSize={maxArrowHeadSize}
          />
          <ArrowHead
            position={scale(data.firstPoint())}
            comingFrom={scale(data.secondPoint())}
            arrowHead={pointerStyles[1]}
            strokeWidth={strokeWidth}
            strokeColor={strokeColor}
            allowedSize={maxArrowHeadSize}
          />
        </Group>
      )}
    </>
  );
});
/**
 * This function prepares an inverted clip area - a "hole" where nothing is drawn,
 * unlike normal clip that defines the area where drawing happens.
 * The trick is to to clip a large area, then clip an "anti" rect inside (drawn counter-clockwise)
 * and use the 'evenodd' clip rule
 * I owe this trick to https://stackoverflow.com/questions/6271419/how-to-fill-the-opposite-shape-on-canvas
 * @param context
 * @param clipRect
 * @param scaleX   - x scale for the clip rect
 * @param scaleY   - y scale for the clip rect
 * @param rotate   - optinal rotation for the clip rect (rotated around its center)
 */
function clipHole(context: Konva.Context, clipRect: IRect, scaleX: number, scaleY: number, rotate?: Degrees) {
  const halfW = clipRect.width / 2,
    halfH = clipRect.height / 2,
    x = clipRect.x + halfW,
    y = clipRect.y + halfH;
  const MAX_CANVAS_SIZE = 32766; // it's 32767 but I'm paranoid

  let path = new Path2D();
  path.rect(-MAX_CANVAS_SIZE / 2, -MAX_CANVAS_SIZE / 2, MAX_CANVAS_SIZE, MAX_CANVAS_SIZE);
  path.rect(scaleX * halfW, -scaleY * halfH, -scaleX * clipRect.width, scaleY * clipRect.height);

  const saved = context._context.getTransform();
  context.translate(x, y); // move to center of the rect, for the rotation
  context.scale(1 / scaleX, 1 / scaleY);
  rotate && context.rotate(-toRadians(rotate));
  context._context.clip(path, "evenodd");
  context._context.setTransform(saved);
}

function ArrowHead({
  arrowHead,
  position,
  comingFrom,
  strokeWidth,
  strokeColor,
  allowedSize,
}: {
  arrowHead: string;
  position: Point;
  comingFrom: Point;
  strokeWidth: number;
  strokeColor: string;
  allowedSize: number;
}) {
  if (arrowHead == "none") return null;
  if (PointUtils.distance(comingFrom, position) < 1) return null;
  let direction = PointUtils.vectorFromTo(comingFrom, position);
  PointUtils.normalize(direction, direction);
  const [p1, p2] = utils.computeArrowPoints(position, direction, strokeWidth, allowedSize);
  return (
    <Line
      points={[p1.x, p1.y, position.x, position.y, p2.x, p2.y]}
      lineCap="round"
      lineJoin="round"
      listening={false}
      strokeWidth={strokeWidth}
      stroke={strokeColor}
    />
  );
}
