import { Circle, Group, Path, Rect } from "react-konva";
import type { IGanttController } from "elements/gantt/controller";
import GanttHeaderDateCell from "elements/gantt/components/date-cell";
import Constants from "elements/gantt/constants";
import type { GanttElement } from "elements/gantt/schema";
import type { ElementProps } from "elements/base/provider";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Konva from "konva";
import {
  getCornerRadius,
  isTargetAnchor,
  isTargetConnector,
  isTargetSplitCell,
  isTargetTaskCell,
} from "elements/gantt/utils";
import GanttTaskComponent, { measureTextTitle } from "elements/gantt/components/task-cell";
import { useEvent } from "react-use";
import { EVT_ELEMENT_DRAG, EVT_ELEMENT_DROP } from "../../canvas-designer-new/elements/card-stack/card-stack-utils";
import { isPointInRect } from "utils/math-utils";
import useObservableController from "elements/hooks/use-observable-controller";
import consts from "shared/consts";
import { resetPointer, setMouseColResize, setMouseDrag, setMousePointer, unsetMouse } from "utils/mouse-cursor-utils";
import { useAtomValue, useSetAtom } from "jotai";
import { focusedElementIdAtom, internalSelectionAtom, selectedElementIdsAtom, transformerRefAtom } from "state-atoms";
import { Degrees } from "utils/transform";
import GhostConnector from "../../canvas-designer-new/elements/connector/ghost-connector";
import { EmptyStateTasks } from "elements/gantt/components/empty-state-tasks";
import { AddActions } from "elements/gantt/components/add-actions/add-actions";
import { KonvaTooltip } from "frontend/canvas-designer-new/utility-elements/konva-tooltip";
import { formatRelatedDates } from "shared/util/date-utils";
import GanttSplitColumn from "elements/gantt/components/split-column";
import useFeatureValue from "frontend/hooks/use-features";
import { GanttConnector } from "elements/gantt/components/gantt-connector/gantt-connector";
import { CanvasKeyboardShortcut, useKeyboardShortcuts } from "utils/keyboard-shortcuts";

export default function GanttElementComponent({ controller: _controller }: ElementProps<GanttElement>) {
  // we cast the controller to GanttController to satisfy the type checker
  const controller = _controller as IGanttController;
  const maxAllowedTasksInPlan = parseInt(useFeatureValue(consts.FEATURE_NAMES.GANTT_MAX_TASKS)) ?? 3;
  const transformerRef = useAtomValue(transformerRefAtom);
  const setInternalTransformer = useSetAtom(internalSelectionAtom);

  // makes it so the component re-renders when the controller changes
  useObservableController(controller, () => {
    if (controller.isSelected()) {
      transformerRef?.current?.forceUpdate();
    }
  });

  const ref = useRef<Konva.Group>(null);
  // it's a string because creating an object of {splitId: string, rowId: string} is expensive
  const [isHoveringSplitRow, setIsHoveringSplitRow] = useState<string | null>(null);
  const [isHoveringTask, setIsHoveringTask] = useState<string | null>(null);
  const [selectedConnectorId, setSelectedConnectorId] = useState<string | null>(null);
  const [taskEditId, setTaskEditId] = useState<string | null>(null);
  const [ghostConnector, setGhostConnector] = useState<{
    from: { taskId?: string; x: number; y: number };
    to: { taskId?: string; x: number; y: number };
  } | null>(null);
  const [isDraggingTask, setIsDraggingTask] = useState(false);

  const setFocusedElementId = useSetAtom(focusedElementIdAtom);
  const setSelectedElementIds = useSetAtom(selectedElementIdsAtom);

  useEvent(EVT_ELEMENT_DRAG, (event) => {
    const taskIdsOnly = event.detail.ids.filter((id: string) => id.startsWith(consts.CANVAS_ELEMENTS.TASK_CARD));
    if (taskIdsOnly.length !== 1) {
      return;
    }
    const ganttBounds = controller.getLayoutRect();
    const internalPoint = {
      x: event.detail.mousePosition.x - ganttBounds.x,
      y: event.detail.mousePosition.y - ganttBounds.y,
    };

    handleDraggedTask(taskIdsOnly[0], internalPoint, event);
  });

  useEvent(EVT_ELEMENT_DROP, (event) => {
    const taskIdsOnly = event.detail.ids.filter((id: string) => id.startsWith(consts.CANVAS_ELEMENTS.TASK_CARD));
    if (taskIdsOnly.length === 0) {
      return;
    }
    const dropArea = controller.getLayoutRect();
    const isDroppedHere = isPointInRect(event.detail, dropArea);
    if (!isDroppedHere) {
      return;
    }
    const internalPoint = {
      x: event.detail.x - dropArea.x,
      y: event.detail.y - dropArea.y,
    };
    handleDraggedTask(taskIdsOnly[0], internalPoint, event);
  });

  useEffect(() => {
    if (maxAllowedTasksInPlan) {
      controller.setMaxAllowedTasksInPlan(maxAllowedTasksInPlan);
    }
  }, [controller, maxAllowedTasksInPlan]);

  useEffect(() => {
    if (
      !controller.isSelected() ||
      (!selectedConnectorId && !controller.getSelectedSplitId() && !controller.getSelectedRowId())
    ) {
      setInternalTransformer((prev) => prev.filter((id) => id !== controller.id));
    } else {
      setInternalTransformer((prev) => [...prev, controller.id]);
    }
  }, [controller.isSelected(), selectedConnectorId, controller.getSelectedSplitId()]);

  useKeyboardShortcuts(
    (shortcut, e) => {
      if (shortcut !== CanvasKeyboardShortcut.delete) {
        return;
      }

      if (selectedConnectorId) {
        controller.removeConnector(selectedConnectorId);
        e.stopPropagation();
      } else if (controller.getSelectedSplitId() || controller.getSelectedRowId()) {
        controller.deleteSelectedElement();
        e.stopPropagation();
      }
    },
    [selectedConnectorId, controller.isSelected()],
    { enabled: controller.isSelected(), disablePropagation: false, capture: true }
  );

  const handleMouseMove = useCallback((e: Konva.KonvaEventObject<MouseEvent>) => {
    if (isTargetAnchor(e.target)) {
      // if the target is an anchor, do nothing
      return;
    }

    if (isTargetSplitCell(e.target)) {
      const [_, splitId, rowId] = e.target.attrs.id.split("-");
      return setIsHoveringSplitRow(`${splitId}-${rowId}`);
    }
    setIsHoveringSplitRow(null);

    if (isTargetTaskCell(e.target)) {
      const [_, taskId] = (e.target.attrs.id ?? "").split("task-");
      return setIsHoveringTask(taskId);
    }
    setIsHoveringTask(null);
    setTaskEditId(null);
  }, []);

  function onMouseLeave() {
    setIsHoveringSplitRow(null);
    setIsHoveringTask(null);
  }

  function renderDateColumnHeader() {
    return controller.getDateColumnsLayout().map((column) => <GanttHeaderDateCell key={column.id} {...column} />);
  }

  function renderSplitColumns() {
    return controller
      .getSplitColumns()
      .sort((a, b) => (a.isSelected() ? 1 : b.isSelected() ? -1 : 0))
      .map((controller) => <GanttSplitColumn key={controller.getSplitId()} controller={controller} />);
  }

  function renderGridLayout() {
    const headerPositions = controller
      .getDateColumnsLayout()
      .filter((h) => h.isHeader)
      .slice(1, Infinity);

    const { height } = controller.getLayoutRect();

    const shouldShowGuideLines = controller.element.granularity !== "day";

    return (
      <>
        {controller.getLayoutCells().map((cell) => {
          return (
            <Rect
              key={`grid-${cell.rowId}-${cell.date}${cell.x}${cell.y}`}
              x={cell.x}
              y={cell.y}
              width={cell.width}
              height={cell.height}
              fill={"white"}
              stroke={Constants.LayoutBorderColor}
              strokeWidth={Constants.LayoutBorderWidth}
              cornerRadius={getCornerRadius(cell.cornerRadius, Constants.HeaderBorderRadius)}
            />
          );
        })}

        {shouldShowGuideLines &&
          headerPositions.map((column) => {
            return <Rect x={column.x - 1} y={column.y} width={2} height={height} stroke="#DADCE0" />;
          })}
      </>
    );
  }

  function renderTaskCells() {
    const tasks = controller.getTaskCells();
    if (tasks.length === 0) {
      const cells = controller.getLayoutCells();
      if (cells.length === 0) {
        return null;
      }
      const xPositions = cells.map((c) => c.x);
      const startX = Math.min(...xPositions);
      const endX = Math.max(...xPositions);

      const width = endX - startX + cells[0].width;
      return (
        <Group listening={false} x={startX} y={cells[0].y}>
          <EmptyStateTasks width={width} height={(cells.at(-2)?.y ?? 200) + 10} />
        </Group>
      );
    }
    return tasks.map((controller) => (
      <GanttTaskComponent
        key={controller.id}
        controller={controller}
        isEditing={taskEditId === controller.id && isHoveringTask === controller.id}
      />
    ));
  }

  function getPointForEvent(e: Konva.KonvaEventObject<DragEvent> | Konva.KonvaEventObject<MouseEvent>) {
    const stage = e.currentTarget.getStage();
    if (!stage) {
      return null;
    }
    const layoutRect = controller.getLayoutRect();

    const stagePosition = stage.getAbsolutePosition();
    const scale = stage.scaleX();

    const point = {
      x: (e.evt.offsetX - stagePosition.x) / scale,
      y: (e.evt.offsetY - stagePosition.y) / scale,
    };

    const internalPoint = {
      x: (point.x - layoutRect.x) / (controller.element.scaleX ?? 1),
      y: (point.y - layoutRect.y) / (controller.element.scaleY ?? 1),
    };

    return internalPoint;
  }

  function renderHoveredTaskAnchors() {
    const [hoveringConnector, setHoveringConnector] = useState<"left" | "right" | null>(null);
    if (!isHoveringTask || controller.isReadOnly()) {
      return null;
    }

    const layout = controller.getTaskCellLayout(isHoveringTask);
    const task = controller.getTaskCells().find((task) => task.id === isHoveringTask);
    if (!layout) {
      return null;
    }

    const padding = 10;
    const lineWidth = 5;
    const y = layout.y + padding;
    const startX = layout.x + padding;
    const endX = layout.x + layout.width - padding - lineWidth;
    const connectorAnchorX = layout.x + layout.width - padding;
    const connectorAnchorY = layout.y + layout.height / 2;

    const onResize = (side: "start" | "end") => (e: Konva.KonvaEventObject<DragEvent>) => {
      setTaskEditId(null);
      setIsDraggingTask(true);

      e.cancelBubble = true;
      const line = e.currentTarget;
      // reset line to original position
      line.y(y);
      line.x(side === "start" ? startX : endX);

      const point = getPointForEvent(e);
      if (!point) {
        return;
      }

      // get the grid cell that the point is hovering over
      const cell = controller.getHoveredGridCell(point);
      if (cell) {
        const startDate = side === "start" ? cell.date : undefined;
        const endDate = side === "end" ? cell.date : undefined;
        controller.changeDateForTask(isHoveringTask, startDate?.getTime(), endDate?.getTime());
      }
    };

    const onDragStart = () => {
      setTaskEditId(null);
      setSelectedElementIds([isHoveringTask]);
    };
    const onDragEnd = () => {
      setTaskEditId(null);
      setSelectedElementIds([]);
      setIsDraggingTask(false);
    };
    const onDrag = (e: Konva.KonvaEventObject<DragEvent>) => {
      setIsDraggingTask(true);

      setTaskEditId(null);

      e.cancelBubble = true;
      const rect = e.currentTarget;
      const point = {
        x: rect.x(),
        y: rect.y() + rect.height() / 2,
      };
      handleDraggedTask(isHoveringTask, point, e);
    };

    const onDragConnectorStart =
      (reversed = false) =>
      () => {
        setTaskEditId(null);
        const from = { taskId: isHoveringTask, x: reversed ? startX : connectorAnchorX, y: connectorAnchorY };
        const to = { x: connectorAnchorX, y: connectorAnchorY };
        setGhostConnector({
          from: reversed ? to : from,
          to: reversed ? from : to,
        });
      };
    const onDragConnector =
      (reversed = false) =>
      (e: Konva.KonvaEventObject<DragEvent>) => {
        setTaskEditId(null);
        e.cancelBubble = true;
        const circle = e.currentTarget;
        // reset line to original position
        circle.y(connectorAnchorY);
        circle.x(connectorAnchorX);

        const point = getPointForEvent(e);
        if (!point) {
          return;
        }

        const task = controller.getHoveredTaskCell(point);
        if (task && task.elementId !== isHoveringTask) {
          const anchor = reversed
            ? {
                x: task.x + task.width - 10,
                y: task.y + task.height / 2,
              }
            : {
                x: task.x + 10,
                y: task.y + task.height / 2,
              };
          setGhostConnector((ghost) => ({
            ...ghost!,
            [reversed ? "from" : "to"]: { ...anchor, taskId: task.elementId },
          }));
        } else {
          setGhostConnector((ghost) => ({
            ...ghost!,
            [reversed ? "from" : "to"]: point,
          }));
        }
      };
    const onDragConnectorEnd = (e: Konva.KonvaEventObject<DragEvent>) => {
      setTaskEditId(null);

      if (!ghostConnector) {
        return;
      }
      if (ghostConnector.from.taskId && ghostConnector.to.taskId) {
        controller.addConnector(ghostConnector.from.taskId, ghostConnector.to.taskId);
      }
      setGhostConnector(null);
    };

    const startShift = layout.isCutStart ? -10 : 0;
    const endShift = layout.isCutEnd ? 10 : 0;

    const { height: textHeight, width: textWidth } = measureTextTitle({
      isEditing: false,
      layout,
      title:
        controller
          .getTaskCells()
          .find((task) => task.id === isHoveringTask)
          ?.getTitle() ?? "",
    });

    const hoverdDate = formatRelatedDates(task?.getStartDate() ?? 0, task?.getEndDate() ?? 0);

    return (
      <Group
        name={"anchor"}
        onDblClick={(e) => {
          setFocusedElementId(isHoveringTask);
        }}
        onClick={() => {
          setTaskEditId(null);
        }}
      >
        <Rect
          id={"anchor-drag-task"}
          x={startX}
          y={y}
          width={endX - startX + 5}
          height={layout.height - padding * 2}
          fill={"transparent"}
          stroke="#00A1FF"
          cornerRadius={[5, 5, 5, 5]}
        />
        <Rect
          id={"anchor-drag-task"}
          x={startX}
          y={y}
          width={endX - startX + 5}
          height={layout.height - padding * 2}
          fill={"transparent"}
          stroke={"transparent"}
          draggable
          onDragMove={onDrag}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          onMouseEnter={(e) => {
            setMouseDrag(e);
            setIsDraggingTask(false);
          }}
          onMouseMove={setMouseDrag}
          onMouseLeave={unsetMouse}
          cornerRadius={[5, 5, 5, 5]}
        />

        <Rect
          id={"anchor-resize-right"}
          x={endX + endShift}
          y={y}
          width={lineWidth}
          height={layout.height - padding * 2}
          fill={"transparent"}
          opacity={0.2}
          cornerRadius={[0, 5, 5, 0]}
          draggable
          onDragMove={onResize("end")}
          onMouseEnter={setMouseColResize}
          onMouseLeave={(e) => {
            unsetMouse(e);
            setIsDraggingTask(false);
          }}
          hitStrokeWidth={15}
        />
        <Rect
          id={"anchor-resize-left"}
          x={startX + startShift}
          y={y}
          width={lineWidth}
          height={layout.height - padding * 2}
          fill={"transparent"}
          opacity={0.2}
          cornerRadius={[5, 0, 0, 5]}
          draggable
          onDragMove={onResize("start")}
          onMouseEnter={setMouseColResize}
          onMouseLeave={(e) => {
            unsetMouse(e);
            setIsDraggingTask(false);
          }}
          hitStrokeWidth={15}
        />
        <Circle
          id={"anchor-connector-right"}
          x={connectorAnchorX + endShift}
          y={connectorAnchorY}
          fill={"#00A1FF"}
          radius={hoveringConnector === "right" ? 8 : 5}
          draggable
          onDragStart={onDragConnectorStart()}
          onDragEnd={onDragConnectorEnd}
          onDragMove={onDragConnector()}
          onMouseEnter={() => setHoveringConnector("right")}
          onMouseLeave={() => setHoveringConnector(null)}
        />
        <Circle
          id={"anchor-connector-left"}
          x={startX + startShift}
          y={connectorAnchorY}
          fill={"#00A1FF"}
          radius={hoveringConnector === "left" ? 8 : 5}
          draggable
          onDragStart={onDragConnectorStart(true)}
          onDragEnd={onDragConnectorEnd}
          onDragMove={onDragConnector(true)}
          onMouseEnter={() => setHoveringConnector("left")}
          onMouseLeave={() => setHoveringConnector(null)}
        />

        {hoveringConnector && (
          <Path
            {...(hoveringConnector === "right"
              ? {
                  x: connectorAnchorX + endShift - 5,
                  y: connectorAnchorY - 5,
                }
              : {
                  x: startX + startShift - 5,
                  y: connectorAnchorY - 5,
                })}
            listening={false}
            stroke="#fff"
            data="M0 5h10M5 0v10"
          />
        )}

        <Group
          x={connectorAnchorX - 15}
          y={connectorAnchorY - 30}
          onClick={() => {
            setFocusedElementId(isHoveringTask);
          }}
          onMouseEnter={setMousePointer}
          id={`task-${isHoveringTask}`}
        >
          <Rect x={0} y={0} width={10} height={10} fill="transparent" id={`task-${isHoveringTask}`} />
        </Group>

        {isHoveringTask !== taskEditId && (
          <Rect
            id={"anchor-drag-task"}
            x={startX + padding}
            y={y + 4}
            width={textWidth}
            height={textHeight}
            onDblClick={(e) => {
              e.cancelBubble = true;
              setTaskEditId(isHoveringTask);
            }}
            draggable
            onDragMove={onDrag}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onMouseEnter={setMouseDrag}
            onMouseMove={setMouseDrag}
            onMouseLeave={unsetMouse}
          />
        )}
        {isDraggingTask && (
          <KonvaTooltip y={y - 40} x={startX + lineWidth / 2} parentWidth={endX - startX} text={hoverdDate} />
        )}
      </Group>
    );
  }

  function renderGhostConnector() {
    if (!ghostConnector) {
      return null;
    }
    return (
      <GhostConnector
        id={"gantt-connector"}
        p1={{ ...ghostConnector.from, rotation: 0 as Degrees }}
        p2={{ ...ghostConnector.to, rotation: 180 as Degrees }}
        lineType={"curve"}
        element={{
          strokeWidth: 1,
          stroke: "#657E9A",
        }}
      />
    );
  }

  function handleDraggedTask(taskId: string, point: { x: number; y: number }, event: any) {
    const cell = controller.getHoveredGridCell(point);
    if (!cell) {
      const layout = controller.getLayoutRect();
      const position = {
        x: point.x + layout.x,
        y: point.y + layout.y,
      };
      controller.dragTasksOut(taskId, position).then((handled) => {
        if (handled) {
          setSelectedElementIds([taskId]);
          resetPointer(event);
        }
      });
      return;
    }
    controller.dropElements(cell.rowId, cell.date, taskId);
  }

  function renderTaskConnectors() {
    const connectors = controller.getConnectors();

    return connectors.map((connector) => {
      const isSelected = selectedConnectorId === connector.id;
      return (
        <GanttConnector
          key={connector.id}
          id={`gantt-connector-${connector.id}`}
          p1={{ ...connector.from, rotation: 0 as Degrees }}
          p2={{ ...connector.to, rotation: 180 as Degrees }}
          lineType={"curve"}
          element={{
            strokeWidth: 1,
            stroke: isSelected ? "#00A1FF" : "#657E9A",
            hitStrokeWidth: 10,
          }}
          removeConnector={() => {
            controller.removeConnector(connector.id);
          }}
          isSelected={isSelected}
        />
      );
    });
  }

  function onDblClick(e: Konva.KonvaEventObject<MouseEvent>) {
    if (controller.isReadOnly()) {
      return;
    }
    const point = getPointForEvent(e);

    if (!point) {
      return;
    }

    const gridCell = controller.getHoveredGridCell(point);
    if (!gridCell) {
      return;
    }
    const existingTask = controller.getHoveredTaskCell(point);
    if (existingTask) {
      // if there is an existing task, we should not create a new one
      return;
    }
    controller.createTask(gridCell.rowId, gridCell.date);
  }

  function onClick(e: Konva.KonvaEventObject<MouseEvent>) {
    if (controller.isReadOnly()) {
      return;
    }

    if (isTargetConnector(e.target)) {
      const [, connectorId] = e.target.attrs.id.split("gantt-connector-");
      setSelectedConnectorId(connectorId);
    } else {
      setSelectedConnectorId(null);
    }

    if (!isTargetAnchor(e.target) && !isHoveringSplitRow) {
      controller.setSelectedColumnRow(null, null);
    }
  }

  function renderSelectedRow() {
    const selectedSplitId = controller.getSelectedSplitId();
    const selectedRowId = controller.getSelectedRowId();
    if (!selectedSplitId || !selectedRowId) {
      return null;
    }
    const row = controller.getSplitCells(selectedSplitId).find((cell) => cell.getRowId() === selectedRowId);
    if (!row) {
      return null;
    }
    const rect = row.getRect();
    const { width } = controller.getLayoutRect();
    return (
      <Rect
        {...rect}
        width={width - rect.x}
        fill="transparent"
        stroke={Constants.SelectedCellBorderColor}
        strokeWidth={2}
        listening={false}
      />
    );
  }

  return (
    <Group
      ref={ref}
      onMouseMove={handleMouseMove}
      onMouseLeave={onMouseLeave}
      listening={true}
      id={"gantt-parent-node"}
      onDblClick={onDblClick}
      onClick={onClick}
    >
      {renderDateColumnHeader()}
      {renderGridLayout()}
      {renderSplitColumns()}
      {renderTaskConnectors()}
      {renderTaskCells()}
      {renderHoveredTaskAnchors()}
      {renderGhostConnector()}
      {renderSelectedRow()}
      {!controller.isReadOnly() && (
        <AddActions
          x={controller.getLayoutRect().width}
          y={-50}
          onNewTask={() => controller.createTask()}
          onNewGroup={() => controller.addSplitLastRow()}
          onNewColumn={() => controller.addSplitColumnRight()}
        />
      )}
    </Group>
  );
}
